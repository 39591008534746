import service from '@/utils/request';
export var deletePromotionResource = function deletePromotionResource(data) {
  return service({
    url: "/promotion_resources/delete",
    method: 'delete',
    data: data
  });
};
export var updatePromotionResource = function updatePromotionResource(data) {
  return service({
    url: "/promotion_resources/update",
    method: 'post',
    data: data
  });
};
export var updatePromotionResourceStatus = function updatePromotionResourceStatus(data) {
  return service({
    url: "/promotion_resources/update_status",
    method: 'post',
    data: data
  });
};
export var getPromotionResourceList = function getPromotionResourceList(params) {
  return service({
    url: "/promotion_resources/list",
    method: 'get',
    params: params
  });
};